import styled from 'styled-components';

export const Logo = styled.img`
  height: 100px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 768px) {
    width: 50%;
    margin-top: 0;
    justify-content: flex-end;
  }
`;
