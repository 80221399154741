/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useAlert} from 'react-alert';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import Base, {ColumnCenter} from '../../components/Base';
import {
  ButtonTertiary,
  Form,
  FormControl,
  H1,
  InputError,
  Link,
} from '../../assets/styles/GlobalStyles';
import {Input} from '../../components/Base/Elements';

const ForgotPassword = ({history}) => {
  const alert = useAlert();

  const changePasswordSubmit = async (values) => {
    await Auth.forgotPassword(values.email);
    alert.show(I18n.get('ForgotPasswordException.codeSendingSuccess'), {
      closeCopy: 'Ok',
    });
    history.push({
      pathname: '/recuperar-senha',
      search: history.location.search,
      state: {
        email: values.email,
      },
    });
  };

  return (
    <Base>
      <ColumnCenter>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            code: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            code: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .matches(/[0-9]/, I18n.get('validate.code.only_number')),
            }),
            password: Yup.string().when('checked', {
              is: (val) => val && val === 'checked',
              then: Yup.string()
                .required(I18n.get('validate.required'))
                .min(8, I18n.get('validate.password.min'))
                .max(24, I18n.get('validate.password.max'))
                .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
                .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
                .matches(/[0-9]/, I18n.get('validate.password.one_number'))
                .matches(
                  /[!@#$%*()_/\\\-+^&{}:;?.]/,
                  I18n.get('validate.password.one_special_character'),
                ),
            }),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                await Auth.signIn(
                  values.email,
                  Math.random()
                    .toString(30)
                    .replace(/[^A-z0-9]+/g, '')
                    .substr(0, 8),
                );
              } catch (e) {
                if (e.code === 'UserLambdaValidationException') {
                  history.push({
                    pathname: '/nova-senha',
                    search: history.location.search,
                    state: {
                      email: values.email,
                    },
                  });
                } else if (
                  e.code === 'NotAuthorizedException' &&
                  e.message === 'Incorrect username or password.'
                ) {
                  await changePasswordSubmit(values);
                } else if (e.code === 'UserNotFoundException') {
                  alert.show(I18n.get('UserNotFoundException.recover.password'), {
                    closeCopy: 'Ok',
                  });
                } else {
                  alert.show(I18n.get('ForgotPasswordException.codeSendingSuccess'), {
                    closeCopy: 'Ok',
                  });
                  history.push({
                    pathname: '/recuperar-senha',
                    search: history.location.search,
                    state: {
                      email: values.email,
                    },
                  });
                }
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting, values}) => (
            <>
              <Form>
                <H1>{I18n.get('forgotPassword.title')}</H1>
                <FormControl>
                  <Field
                    name="email"
                    value={values.email.toLowerCase()}
                    placeholder={I18n.get('input.forgotPassword')}
                    component={Input}
                  />
                  <ErrorMessage name="email" component={InputError} />
                </FormControl>
                <ButtonTertiary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? I18n.get('button.sending') : I18n.get('button.send.code')}
                </ButtonTertiary>
              </Form>

              <FormControl>
                <Link
                  onClick={() => {
                    history.push({
                      pathname: '/login',
                      search: history.location.search,
                    });
                  }}>
                  {I18n.get('register.columnLeft.login')}
                </Link>
              </FormControl>
            </>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default ForgotPassword;
