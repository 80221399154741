import styled from 'styled-components';

export const Logo = styled.img`
  max-width: 200px;
`;

export const Header = styled.header`
  background-color: transparent,
  padding: 20px 0,
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;
