import styled from 'styled-components';

export const Footer = styled.footer`
  width: 100%;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 50px;
  }
`;

export const ContainerMenus = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Menu = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

export const InternContainerMenus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid rgba(79, 94, 120, 0.15);
  margin-top: 35px;
  margin-bottom: 15px;
`;
