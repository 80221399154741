export const data = {
  '@context': '/v1/contexts/Menu',
  '@id': '/v1/menus/3',
  '@type': 'Menu',
  id: 3,
  title: 'Redes Sociais',
  slug: 'redes-sociais',
  menuItems: [
    {
      '@id': '/v1/menu_items/15',
      '@type': 'MenuItem',
      id: 15,
      title: 'Facebook',
      position: 0,
      icon: 'fab fa-facebook-f',
      target: '_blank',
      url: '#',
      menu: '/v1/menus/3',
      parentMenu: null,
    },
    {
      '@id': '/v1/menu_items/16',
      '@type': 'MenuItem',
      id: 16,
      title: 'Instagram',
      position: 0,
      icon: 'fab fa-instagram',
      target: '_blank',
      url: '#',
      menu: '/v1/menus/3',
      parentMenu: null,
    },
    {
      '@id': '/v1/menu_items/17',
      '@type': 'MenuItem',
      id: 17,
      title: 'Linkedin',
      position: 0,
      icon: 'fab fa-linkedin-in',
      target: '_blank',
      url: '#',
      menu: '/v1/menus/3',
      parentMenu: null,
    },
  ],
};
