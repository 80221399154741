import React from 'react';
import './styles.css';
import facebookLogo from './facebook.png'; // Tell webpack this JS file uses this image

const FacebookButton = ({onClick}) => (
  <button type="button" className="facebook-button" onClick={onClick}>
    <span className="facebook-button__icon">
      <img src={facebookLogo} alt="facebook-logo" />
    </span>
    <span className="facebook-button__text">Entrar com o Facebook</span>
  </button>
);

export default FacebookButton;
