export const data = {
  '@context': '/v1/contexts/Menu',
  '@id': '/v1/menus/2',
  '@type': 'Menu',
  id: 2,
  title: 'Menu Rodapé',
  slug: 'menu-rodape',
  menuItems: [
    {
      '@id': '/v1/menu_items/13',
      '@type': 'MenuItem',
      id: 13,
      title: 'Políticas',
      position: 0,
      icon: null,
      target: '_self',
      url: '#',
      menu: '/v1/menus/2',
      parentMenu: null,
    },
    {
      '@id': '/v1/menu_items/14',
      '@type': 'MenuItem',
      id: 14,
      title: 'Contato',
      position: 1,
      icon: null,
      target: '_self',
      url: `${process.env.REACT_APP_ACADEMY_URL}/contato`,
      menu: '/v1/menus/2',
      parentMenu: null,
    },
  ],
};
