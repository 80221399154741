import React from 'react';
import {Normalize} from 'styled-normalize';
import {Main, Wrapper} from './styled';
import Footer from '../_partials/Footer';
import Header from '../_partials/Header';
import bg from '../../assets/images/bj.jpg';

export * from './ColumnCenter';

const Base = ({children, showBackgroundimage}) => {
  return (
    <>
      <Normalize />
      <Main
        style={
          showBackgroundimage
            ? {
                // eslint-disable-next-line max-len
                backgroundImage: `linear-gradient(180deg, rgba(11, 17, 30, 0.33), rgba(11, 17, 30, 0.33)), linear-gradient(180deg, rgba(54, 181, 171, 0.3), #0b111e), url('${bg}')`,
              }
            : {}
        }>
        <Header />
        <Wrapper>{children}</Wrapper>
        <Footer />
      </Main>
    </>
  );
};


export default Base;
