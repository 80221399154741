import React from 'react';
import PropTypes from 'prop-types';
import {ColumnRight as ColumnRightStyled, Title} from './styled';

const ColumnCenter = ({children, title}) => {
  return (
    <ColumnRightStyled>
      {title ? <Title>{title}</Title> : <></>}
      {children}
    </ColumnRightStyled>
  );
};

ColumnCenter.propTypes = {
  children: PropTypes.any.isRequired,
};
export {ColumnCenter};
