import styled, {createGlobalStyle} from 'styled-components';
import {Form as FormFormik} from 'formik';
import {Link as LinkRouter} from 'react-router-dom';
import InputMask from 'react-input-mask';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *, button, input {
    border: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  body{
    font-size: 16px;
    color: #ffffff;
    background-color: #0b111e;
  }
  
  .complete {
    text-decoration: line-through;
  }
  
  
  [type="checkbox"]:focus, [type="radio"]:focus {
    box-shadow: inset 0 0 1px 1px rgb(54, 181, 171);
  }
`;

export const ButtonDefault = styled.button`
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 300ms ease;
  font-weight: 400;
  display: block;
  margin: 10px 0;
  padding: 8px 35px;
  text-transform: uppercase;
  border-radius: 20px;
  &[disabled] {
    opacity: 0.4;
    cursor: no-drop;
  }

  @media (min-width: 768px) {
    align-self: center;
  }
`;

export const Button = styled(ButtonDefault)`
  background: rgb(54, 181, 171);
  color: #fff;
  &:hover {
    opacity: 0.8;
    transform: translateY(-3px);
  }
`;
export const ButtonTertiary = styled(Button)`
  background: rgb(54, 181, 171);
  color: #fff;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  &:hover {
    opacity: 0.8;
    transform: translateY(-3px);
  }
`;

export const ButtonSecondary = styled.button`
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 15px;
`;

export const ButtonNoFormat = styled.div`
  cursor: pointer;
  display: contents !important;
  text-decoration: underline;
  font-weight: bold;
  margin: 10px 0;
`;

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: start;
  margin: 25px 0 25px;
`;

export const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  > input {
    margin-right: 5px;
  }
  > a {
    color: rgb(54, 181, 171);
  }
`;

export const Hint = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  > div {
    margin-top: 3px;
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 11px;
    }
  }
`;

export const InputError = styled.div`
  font-size: 12px;
  color: #d70000;
  text-align: left;
  margin-top: 3px;
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 20px 0;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 14px !important;
  padding-left: 2px;
  text-align: start;
  margin-top: 5px;
  outline: none !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.26) !important;
  &::placeholder {
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
  }
  &:focus {
    border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
  }
`;

export const InputMasked = styled(InputMask)`
  height: 40px;
  width: 100%;
  padding: 20px 0;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 14px !important;
  padding-left: 2px;
  text-align: start;
  margin-top: 5px;
  outline: none !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.26) !important;
  &::placeholder {
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
  }
  &:focus {
    border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
  }
`;

export const Link = styled(LinkRouter)`
  display: inline-flex;
  color: #ffffff;
  font-size: 18px;
  margin-top: 25px;
  margin: 10px 0;
  font-weight: 300;
  &:hover {
    text-decoration: none;
  }
`;

export const Relative = styled.div`
  position: relative;
`;

export const CapsLock = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  background: #eee;
  color: #555;
  padding: 0 5px;
  line-height: 22px;
  border: 1px solid rgb(54, 181, 171);
  z-index: 9;
  right: 10px;
  display: none;
  &.active {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: 0;
    border-bottom: 7px solid rgb(54, 181, 171);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  &:after {
    content: '';
    position: absolute;
    top: -6px;
    right: 1px;
    border-bottom: 7px solid #eee;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

export const ContainerActions = styled.div`
  margin-top: 20px;
`;

export const Line = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 30px 0;

  &:after {
    position: absolute;
    right: 0;
    width: 47%;
    content: '';
    height: 1px;
    background-color: #a9a9a9;
  }
  &:before {
    position: absolute;
    left: 0;
    width: 47%;
    content: '';
    height: 1px;
    background-color: #a9a9a9;
  }
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  .labe-check-terms {
    position: relative;
  }
  .term-use {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .check-terms:checked {
    display: initial;
    position: absolute;
    top: 1px;
    left: 2px;
  }
  .check-terms:checked + span {
  }
  .check-terms {
    display: none;
  }
  .span-check-terms {
    width: 15px;
    height: 14px;
    border-radius: 31%;
    border: 1px solid #ccc;
    display: block;
    margin-right: 10px;
    cursor: pointer;
  }
  .span-check-terms:hover {
    box-shadow: 0 0 5px #36b5ab;
  }
`;

export const ExternLink = styled.a`
  color: #666e7a;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0;
  margin-left: 5px;
  text-decoration: underline;
`;
export const ExternLinkContainer = styled.div`
  color: #666e7a;
  font-size: 14px;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 0;
  text-decoration: none;
`;
