import React from 'react';
import SocialItem from './_partials/SocialItem';
import TitleMenuFooter from '../TitleMenuFooter';
import {data as SocialData} from './json';
import {Container} from './styles';

const RedesSociais = ({content, title}) => {
  const data = content.length ? content : SocialData.menuItems;
  return (
    <Container>
      <TitleMenuFooter>{title}</TitleMenuFooter>
      {data &&
        data.length &&
        data.map((item, index) => (
          <SocialItem
            key={index}
            href={item.url}
            className={`${item.title} ${item.icon}`}
            alt={item.title}>
            {item.title}
          </SocialItem>
        ))}
    </Container>
  );
};

export default RedesSociais;
