import React, {useState, useEffect} from 'react';
import {Footer, ContainerMenus, Menu, InternContainerMenus, Line} from './styled';
import Copyright from '../Copyright';
import RedesSociais from '../RedesSociais';
import DefaultMenuFooter from '../DefaultMenuFooter';
import MenuFooter from '../MenuFooter';

const FooterComponent = () => {
  const [menuFooter, setMenuFooter] = useState([]);
  const [menuSocial, setMenuSocial] = useState([]);

  useEffect(() => {
    /*
    const urlBase = process.env.REACT_APP_API_URL;
    axios
      .get(`${urlBase}/menus/slug/menu-rodape`)
      .then((response) => {
        setMenuFooter(response.data ? response.data.menuItems : []);
      })
      .catch((e) => {
        console.error('Base.menuFooter', e);
      });

    axios
      .get(`${urlBase}/menus/slug/redes-sociais`)
      .then((response) => {
        setMenuSocial(response.data ? response.data.menuItems : []);
      })
      .catch((e) => {
        console.error('Base.menuSocial', e);
      });
*/
    return () => {
      setMenuFooter([]);
      setMenuSocial([]);
    };
  }, []);

  return (
    <Footer>
      <div>
        <ContainerMenus>
          <InternContainerMenus>
            <Menu>
              <MenuFooter content={menuFooter} title="Institucional" />
            </Menu>
            <Menu>
              <RedesSociais content={menuSocial} title="Social" />
            </Menu>
          </InternContainerMenus>
          <DefaultMenuFooter />
        </ContainerMenus>
        <Copyright />
      </div>
      <Line />
    </Footer>
  );
};

export default FooterComponent;
