import React from 'react';
import {Copyright, Container} from './styles';

const Copyrights = () => {
  return (
    <Container>
      <Copyright>© MEU SHAPE.COM 2020 Todos os direitos reservados.</Copyright>
    </Container>
  );
};

export default Copyrights;
