import React from 'react';
import {Item, Container} from './styles';
import TitleMenuFooter from '../TitleMenuFooter';
import {data as FooterData} from './json';

const MenuFooter = ({content, title}) => {
  const data = content.length ? content : FooterData.menuItems;
  return (
    <Container>
      <TitleMenuFooter>{title}</TitleMenuFooter>
      {data &&
        data.length &&
        data.map((item, index) => (
          <Item key={index} href={item.url} target={item.target}>
            {item.title}
          </Item>
        ))}
    </Container>
  );
};

export default MenuFooter;
