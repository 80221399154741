import React from 'react';
import Logo from '../../../assets/images/logo.png';
import {Logo as Img, Box, Header as Container} from './styled';

const Header = () => {
  return (
    <Container>
      <Box>
        <a href={process.env.REACT_APP_ACADEMY_URL} rel="noopener noreferrer">
          <Img src={Logo} alt="Meu Shape" />
        </a>
      </Box>
    </Container>
  );
};

export default Header;
