import React from 'react';
import {Logo, Container} from './styles';
import LogoMeuShape from '../../../assets/images/logo.png';

const DefaultMenuFooter = () => {
  return (
    <Container>
      <a href={process.env.REACT_APP_ACADEMY_URL}>
        <Logo src={LogoMeuShape} alt="Meu Shape" />
      </a>
    </Container>
  );
};

export default DefaultMenuFooter;
