/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Auth, I18n} from 'aws-amplify';
import InputMasked from 'react-input-mask';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import {useAlert} from 'react-alert';
import Base, {ColumnCenter} from '../../components/Base';
import {
  ButtonTertiary,
  ButtonNoFormat,
  Form,
  FormControl,
  H1,
  InputError,
  Link,
} from '../../assets/styles/GlobalStyles';
import {verifyError} from '../../utils/verifyErros';
import {Input} from '../../components/Base/Elements';

const ConfirmSignUp = ({history}) => {
  const alert = useAlert();
  const hasEmail = history.location.state ? history.location.state.email || '' : '';
  const hasPassword = history.location.state ? history.location.state.password || '' : '';

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(hasEmail);
      alert.show(I18n.get('confirmSignUp.codeResentSuccessfully'), {
        closeCopy: 'Ok',
      });
    } catch (e) {
      alert.show(I18n.get('confirmSignUp.errorResendingCode'), {
        closeCopy: 'Ok',
      });
    }
  };

  return (
    <Base>
      <ColumnCenter>
        <Formik
          enableReinitialize
          initialValues={{
            email: hasEmail,
            code: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            code: Yup.string()
              .required(I18n.get('validate.required'))
              .matches(/[0-9]/, I18n.get('validate.code.only_number')),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                await Auth.confirmSignUp(values.email, values.code);
                if (hasPassword) {
                  alert.show(I18n.get('confirmSignUp.verifiedUser'), {
                    closeCopy: 'Ok',
                  });
                  await Auth.signIn(values.email, hasPassword);
                  history.push({
                    pathname: '/',
                    search: history.location.search,
                    state: {
                      email: values.email,
                    },
                  });
                } else {
                  alert.show(I18n.get('confirmSignUp.verifiedUser'), {
                    closeCopy: 'Ok',
                  });
                  history.push({
                    pathname: '/login',
                    search: history.location.search,
                    state: {
                      email: values.email,
                    },
                  });
                }
              } catch (e) {
                actions.setFieldValue('code', '');
                alert.show(verifyError(e.code, e.message), {
                  closeCopy: 'Ok',
                });
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <Form>
              <H1>{I18n.get('confirmSignUp.title')}</H1>

              <FormControl>
                <Field
                  type="hidden"
                  name="email"
                  disabled={!!hasEmail}
                  placeholder={I18n.get('input.email')}
                  component={Input}
                />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>

              <FormControl>
                <Field name="code">
                  {({field}) => (
                    <InputMasked
                      {...field}
                      placeholder={I18n.get('input.code')}
                      maskChar=""
                      mask="999999">
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMasked>
                  )}
                </Field>
                <ErrorMessage name="code" component={InputError} />
              </FormControl>
              <ButtonTertiary type="submit" disabled={isSubmitting}>
                {isSubmitting ? I18n.get('button.sending') : I18n.get('button.send')}
              </ButtonTertiary>

              {!!hasEmail && (
                <Link as={ButtonNoFormat} onClick={resendConfirmationCode}>
                  {I18n.get('confirmSignUp.resendConfirmationCode')}
                </Link>
              )}
              <Link
                onClick={() => {
                  history.push({
                    pathname: '/login',
                    search: history.location.search,
                  });
                }}>
                {I18n.get('register.change.email')}
              </Link>
            </Form>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default ConfirmSignUp;
