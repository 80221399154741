import styled from 'styled-components';
import icoEye from '../../assets/images/ico-eye.png';

export const Main = styled.div`
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(11, 17, 30, 0.33), rgba(11, 17, 30, 0.33)),
    linear-gradient(180deg, rgba(54, 181, 171, 0.3), #0b111e);
  background-position: 0 0, 0 0;
  background-size: auto, auto;
`;

export const Wrapper = styled.div`
  background-color: transparent;
  border: 1px solid #36b5ab;
  border-radius: 20px;
  width: 90vw;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    width: 35vw;
    min-width: 550px;
    height: fit-content;
    display: flex;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    padding: 30px 45px;
  }
`;

export const ColumnRight = styled(Column)``;

export const IcoView = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url('${icoEye}') no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`;

export const Title = styled.h1`
  margin-top: 10px;
  font-size: 20px;
  text-align: left;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
  text-transform: uppercase;
`;
