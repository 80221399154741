/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import {useAlert} from 'react-alert';
import {clearCookie} from '../../utils/cookies';
import Base, {ColumnCenter} from '../../components/Base';
import {Input, InputPassword, InputMask} from '../../components/Base/Elements';
import {
  ButtonTertiary,
  Form,
  FormControl,
  Line,
  Hint,
  InputError,
  Link,
  Terms,
  ExternLink,
  ExternLinkContainer,
} from '../../assets/styles/GlobalStyles';
import {passwordValidation, verifyError} from '../../utils/verifyErros';
import FacebookButton from '../../components/Facebook/FacebookButton';
import GoogleButton from '../../components/Google/GoogleButton';

const SignUp = ({history}) => {
  const alert = useAlert();
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false,
  });
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    (async () => {
      clearCookie();
    })();
  }, []);

  return (
    <Base showBackgroundimage>
      <ColumnCenter title="Seus dados">
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required(I18n.get('validate.required'))
              .min(5, I18n.get('validate.name.min')),
            email: Yup.string()
              .email(I18n.get('validate.invalid_email'))
              .required(I18n.get('validate.required')),
            phone: Yup.string()
              .required(I18n.get('validate.required'))
              .min(16, I18n.get('validate.phone.incorrect')),
            password: Yup.string()
              .required(I18n.get('validate.required'))
              .min(8, I18n.get('validate.password.min'))
              .max(24, I18n.get('validate.password.max'))
              .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
              .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
              .matches(/[0-9]/, I18n.get('validate.password.one_number'))
              .matches(
                /[!@#$%*()_/\\\-+^&{}:;?.]/,
                I18n.get('validate.password.one_special_character'),
              ),
            confirmPassword: Yup.string().when('password', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('password')], I18n.get('validate.password.not_equals'))
                .required(I18n.get('validate.required')),
            }),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                const data = {
                  username: values.email.toLowerCase(),
                  password: values.password,
                  attributes: {
                    name: values.name,
                    phone_number: `+55${values.phone.replace(/[^0-9]/g, '')}`,
                  },
                };
                await Auth.signUp(data);

                alert.show(I18n.get('send.code.email'), {
                  closeCopy: 'Ok',
                });

                history.push({
                  pathname: '/cadastro-verificacao',
                  search: history.location.search,
                  state: {
                    email: values.email.toLowerCase(),
                    password: values.password,
                  },
                });
              } catch (e) {
                alert.show(verifyError(e.code, e.message), {
                  closeCopy: 'Ok',
                });
                history.push({
                  pathname: '/login',
                  search: history.location.search,
                });
              }
            }
            actions.setSubmitting(false);
          }}>
          {({isSubmitting}) => (
            <>
              <Form>
                <div>
                  <FacebookButton onClick={() => Auth.federatedSignIn({provider: 'Facebook'})} />
                  <GoogleButton onClick={() => Auth.federatedSignIn({provider: 'Google'})} />
                  <Line>ou</Line>
                </div>

                <FormControl>
                  <Field
                    name="name"
                    placeholder={I18n.get('input.name')}
                    component={Input}
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={1}
                  />
                  <ErrorMessage name="name" component={InputError} />
                </FormControl>

                <FormControl>
                  <Field
                    name="email"
                    placeholder={I18n.get('input.email')}
                    component={Input}
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={2}
                  />
                  <ErrorMessage name="email" component={InputError} />
                </FormControl>

                <FormControl>
                  <Field
                    name="phone"
                    maskChar={null}
                    mask="(99) \9 9999-9999"
                    placeholder={I18n.get('input.phone')}
                    component={InputMask}
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={3}
                  />
                  <ErrorMessage name="phone" component={InputError} />
                </FormControl>

                <FormControl>
                  <InputPassword
                    type="password"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={4}
                    name="password"
                    placeholder={I18n.get('input.password')}
                    component={Input}
                    onInput={(value) => {
                      passwordValidation(value.target.value, setPasswordValidate);
                    }}
                  />
                  <ErrorMessage name="password" component={InputError} />
                </FormControl>

                <FormControl>
                  <InputPassword
                    type="password"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={5}
                    name="confirmPassword"
                    placeholder={I18n.get('input.confirmPassword')}
                    component={Input}
                  />
                  <ErrorMessage name="confirmPassword" component={InputError} />
                  <Hint>
                    <div>{I18n.get('input.password.hint_title')}</div>
                    <ul>
                      <li className={`${passwordValidate.min ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint1')}
                      </li>
                      <li className={`${passwordValidate.number ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint2')}
                      </li>
                      <li className={`${passwordValidate.letterlowercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint3')}
                      </li>
                      <li className={`${passwordValidate.letteruppercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint4')}
                      </li>
                      <li className={`${passwordValidate.letterespecial ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint5')}
                      </li>
                    </ul>
                  </Hint>
                </FormControl>

                <FormControl>
                  <Terms>
                    <label className="labe-check-terms">
                      <input
                        type="checkbox"
                        className="check-terms"
                        name="terms-use"
                        onClick={(e) => setTerms(e.target.checked)}
                      />
                      <span className="span-check-terms" />
                    </label>
                    <ExternLinkContainer>
                      Eu aceito os
                      <ExternLink
                        href={`${process.env.REACT_APP_ACADEMY_URL}/termos-de-uso`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-termos">
                        Termos de Uso
                      </ExternLink>
                    </ExternLinkContainer>
                  </Terms>
                </FormControl>

                <ButtonTertiary type="submit" disabled={isSubmitting || !terms}>
                  {isSubmitting ? I18n.get('button.registering') : I18n.get('button.register')}
                </ButtonTertiary>
              </Form>
              <FormControl>
                <Link
                  onClick={() => {
                    history.push({
                      pathname: '/login',
                      search: history.location.search,
                    });
                  }}>
                  {I18n.get('register.columnLeft.login')}
                </Link>
              </FormControl>
            </>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default SignUp;
