import styled from 'styled-components';

export const Item = styled.a`
  font-size: 16px;
  color: #a9afaa !important;
  text-decoration: none !important;
  margin: 8px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;
