/* eslint-disable max-len */
export const dictionary = {
  // Inputs and others elements
  'label.email': 'E-mail',
  'label.password': 'Senha',
  'label.code': 'Código',
  'label.newpassword': 'Defina nova senha',
  'input.name': 'Digite seu nome',
  'input.email': 'Digite seu e-mail',
  'input.password': 'Digite sua senha',
  'input.new_password': 'Nova senha',
  'input.password.hint_title': 'A senha deve ter:',
  'input.password.hint1': 'Mínimo de 8 caracteres',
  'input.password.hint2': '1 número',
  'input.password.hint3': '1 letra minúscula',
  'input.password.hint4': '1 letra maiúscula',
  'input.password.hint5': '1 caractere especial (*&$!)',
  'input.phone': 'Seu número de celular',
  'input.confirmPassword': 'Confirme a senha',
  'input.code': 'Código de verificação',
  'button.enter': 'Entrar',
  'button.alter': 'Salvar',
  'button.altering': 'Salvando...',
  'button.entering': 'Entrando..',
  'button.register': 'Cadastrar',
  'button.registering': 'Cadastrando...',
  'button.confirm': 'Confirmar',
  'button.confirming': 'Confirmando...',
  'button.send': 'Enviar',
  'button.sending': 'Enviando...',
  'button.send.code': 'Prosseguir',
  'validate.required': 'Campo obrigatório',
  'validate.invalid_email': 'E-mail inválido',
  'validate.min_password': 'Mínimo de 8 caracteres',
  'validate.password.min': 'Senha com no mínimo 8 caracteres',
  'validate.name.min': 'Nome com no mínimo 5 caracteres',
  'validate.password.max': 'Senha com no máximo 24 caracteres',
  'validate.password.one_lowercase_character': 'Pelo menos um caractere minúsculo',
  'validate.password.one_uppercase_character': 'Pelo menos um caractere maiúsculo',
  'validate.password.one_number': 'Pelo menos um número',
  'validate.password.one_special_character': 'Pelo menos um caractere especial ex: @,$,!,%...',
  'validate.password.not_equals': 'As senhas não são iguais',
  'validate.code.only_number': 'O código só possui números',
  'validate.phone.incorrect': 'Número incorreto',
  'login.new_password': 'Você precisa alterar sua senha!',

  // Erros
  'CodeMismatchException.invalid_verification_code':
    'Código de verificação inválido, tente novamente.',
  'ForgotPasswordException.codeSendingSuccess':
    'Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique sua caixa de spam ou repita o processo.',
  'ForgotPasswordExceptionImport.codeSendingSuccess':
    'Sua senha expirou! Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.',
  'ForgotPasswordException.defaultError': 'Erro ao tentar recuperar a senha, tente novamente.',
  'ForgotPasswordException.passwordChangedSuccessfully': 'Sua senha foi alterada com sucesso!',
  'NotAuthorizedException.incorrect': 'E-mail e/ou senha incorretos.',
  'UserException.login': 'Ocorreu um erro ao se conectar, tente novamente.',
  'UserNotFoundException.notFound': 'E-mail e/ou senha incorretos.',
  'UsernameExistsException.email_already_exists': 'Já existe uma conta com o e-mail informado.',
  'UserNotConfirmedException.user_is_not_confirmed': 'Usuário não confirmado.',
  'ExpiredCodeException.invalid_code': 'Código inválido/expirado',
  'LimitExceededException.limit_exceeded':
    'Limite de tentativas excedidas, tente novamente mais tarde',
  'NotAuthorizedException.ExpiredTempPassword':
    'A senha temporária expirou e deve ser redefinida por um administrador.',
  'NotAuthorizedException.UserPasswordCantReset':
    'A senha do usuário não pode ser redefinida no estado atual.',
  'Invalid.phone.number.format': 'Número de telefone possui um formato inválido.',
  'UserNotFoundException.recover.password': 'Usuário não existe',

  // Login
  'login.columnLeft.title': 'É o primeiro acesso? Inscreva-se aqui!',
  'login.columnLeft.register': 'Novo aqui? Inscreva-se!',
  'login.title': 'Acesse sua conta',
  'login.resetPassword': 'Esqueceu a senha?',

  // Registre-se
  'register.columnLeft.title': 'Já possui cadastro? Clique aqui!',
  'register.columnLeft.login': 'Voltar para o login',
  'register.title': 'Faça seu cadastro',
  'register.columnRight.notify': 'Eu aceito receber alertas de novos conteúdos por e-mail',
  'send.code.email':
    'Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.',

  // Confirmar Registro
  'confirmSignUp.title':
    'FOI ENVIADO UM CÓDIGO PARA SEU E-MAIL, VERIFIQUE E COLE O CÓDIGO NO CAMPO ABAIXO.',
  'confirmSignUp.resendConfirmationCode': 'Não recebeu? enviar novamente',
  'confirmSignUp.codeResentSuccessfully': 'Código enviado com sucesso',
  'confirmSignUp.errorResendingCode': 'Erro ao enviar código, tente novamente.',
  'confirmSignUp.verifiedUser': 'Usuário verificado com sucesso.',

  'forgotPassword.title': 'ESQUECEU A SENHA? DIGITE SEU E-MAIL E ENVIAREMOS UM CÓDIGO DE SEGURANÇA',
  'passwordRecovery.title': 'Informe o código que enviamos no seu e-mail e cadastre uma nova senha',
  'newPassword.title': 'Informe o código que enviamos no seu e-mail e cadastre uma nova senha',
  'input.forgotPassword': 'Digite seu e-mail',
  'button.send.recoveryPassword': 'Salvar nova senha',
  'register.change.email': 'Trocar de e-mail',
};
