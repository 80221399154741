/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0732375c-ec5b-48ab-8174-c2ee9c5c66b1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0SzLXIHHe",
    "aws_user_pools_web_client_id": "3ifrgnd06mvqn0l8sp6na9f94e",
    "oauth": {}
};


export default awsmobile;
