import React from 'react';
import './styles.css';
import googleLogo from './google.png';

const GoogleButton = ({onClick}) => (
  <button type="button" className="google-button" onClick={onClick}>
    <span className="google-button__icon">
      <img src={googleLogo} alt="google-logo" />
    </span>
    <span className="google-button__text">Entrar com o Google</span>
  </button>
);

export default GoogleButton;
